:root {
  --primary: #186300;
  --secondary: #c3997c;
  --tertiary: #193b3c;
  --white: #ffffff;
  --text: #272e35;
}

body #root~iframe {
  display: none;
}

html {
  overflow: auto;
}

.main-content {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.sunset-F2F2F2-bg.home-about-sunset {
  padding: 3rem 0 !important;
}

.global-community {
  padding: 5%;
}

.frequently-asked-question-main .nav-pills .nav-link.active {
  border-radius: 24px !important;
}

.discover-button .fa-long-arrow-right {
  width: 100%;
  text-align: right;
  padding-top: 40px;
  font-size: 40px;
  font-weight: 100;
}

.header-main .navbar-light .navbar-nav .nav-link:focus,
.header-main .navbar-light .navbar-nav .nav-link:hover {
  color: var(--secondary) !important;
}

.carousel-indicators.position-static {
  padding-top: 3%;
}

.carousel-indicators.position-static button {
  border-top: 0;
  border-bottom: 0;
}

.card.border-radidus-8 {
  filter: drop-shadow(0px 1px 7px rgba(196, 196, 196, 0.9));
  cursor: pointer;
}

.home-nature-section .icon-card .nft-feature,
.energy-token .icon-card .nft-feature {
  border: none;
}

.home-nature-section .icon-card .nft-feature:hover,
.energy-token .icon-card .nft-feature:hover {
  filter: drop-shadow(0px 1px 7px rgba(196, 196, 196, 0.9));
  cursor: pointer;
}

.home-nature-section .icon-card .nft-feature:hover p font,
.energy-token .icon-card .nft-feature:hover p font {
  color: #000 !important;
}

.nav-item .nav-link {
  font-weight: 500;
  font-size: 18px;
}

.Loader {
  min-height: calc(150vh - 400px);
}

.Loader {
  position: fixed;
  left: 45%;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999999;
  width: 100%;
}

.Loader2 {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.home-main .partners img {
  width: auto !important;
  margin: 0 auto;
}

.home-main .partners .center-img {
  width: 100%;
}

.home-main .partners .row.mt-5 {
  margin: 0;
}

.discover-button {
  width: 100%;
  height: 100%;
  margin-top: 0;
  padding: 10px;
}

.signup-main .card-body {
  z-index: 1;
}

.banner-right-image .columns {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: auto;
  text-align: center;
}

.banner-right-image .columns img {
  margin-bottom: 20px;
}

.banner-right-image .first-column,
.banner-right-image .second-column,
.banner-right-image .third-column {
  padding: 0 10px;
}

.fact-and-figures h5 {
  font-weight: 600;
  font-size: 14px;
}

.home-main div.fact-and-figures div.card {
  opacity: 0.9;
}

.fact-and-figures .img-right {
  padding-top: 3rem;
}

.otp-inputs input {
  width: 54px !important;
  text-align: center !important;
  border: 1px solid black;
  border-radius: 8px;
  height: 54px;
  font-size: 12px;
  color: rgb(0, 0, 0);
  font-weight: 400;
}

.otp-inputs label.form-label.text-primary {
  text-align: center;
  margin: 20px auto !important;
  width: 100%;
  color: #fc1321eb !important;
}

.projects-section.project img {
  max-height: 348px;
}

/*.slider-vertical-control button.slick-arrow {
  position: initial;
  width: 100%;
}*/
/*.slider-vertical-control .slick-list .slick-track{display: flex;}*/
.slider-vertical-control .slick-prev:before,
.slider-vertical-control .slick-next:before {
  color: #2e4d4d;
}

.slider-vertical-control .slick-slider.slick-vertical {
  top: 26px;
}

.single-slider-img {
  border-radius: 12px;
  width: 100%;
}

.detail-about .det-about {
  padding: 20px;
}

.slider-vertical-control .slick-slide img {
  margin: 10px auto 4px;
}

.project-slider .carousel-inner button.slick-arrow {
  display: none !important;
}

.project-slider .slick-arrow.slick-next {
  right: 0;
}

.project-slider .slick-arrow.slick-prev {
  left: 0;
}

.slider-vertical-control {
  background-color: white;
  border-radius: 10px;
}

.nature-project-list {
  margin: 0 10px;
}

.nature-project-list img {
  min-height: 400px;
  max-height: 400px;
}

.slick-dots li button:before {
  font-size: 10px !important;
}

.slick-slider .slick-dots .slick-active button:before {
  color: #00db16;
}

.nature-project-list .border-2-909090 {
  padding: 0 10px;
}

.mt-5.about-us {
  padding-top: 2rem;
}

.mt-5.about-us {
  padding-top: 2rem;
}

.investor-detail {
  margin-top: 5rem;
}

.investor-detail .description {
  padding: 0 3rem 2rem;
}

.buy-token-action .cancel-button {
  margin-left: 20px;
  border-radius: 8px;
}

.kyc-verification .kyc-status {
  display: flex;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  text-align: center;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.side-bar-main {
  width: 8%;
  position: fixed;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 5;
}

.side-bar-main div div nav {
  margin-top: 6rem;
}

.page-content {
  width: 90%;
  margin-left: 9%;
  margin-top: 6rem;
}

#naturenft .table-main,
#energynft .table-main {
  overflow: unset;
}

.kyc-verification .kyc-status {
  align-items: center;
}

.kyc-verification .kyc-status .pending-notification {
  margin-right: 5px;
}

.kyc-verification .kyc-heading {
  text-align: right;
}

.kyc-verification .kyc-status {
  float: right;
}

.input-group:focus-within .input-group-text,
.form-control:focus~.input-group-text {
  box-shadow: 1px 2px 20px rgb(0 0 0 / 9%);
  border-color: #86b7fe;
}

.form-control:focus {
  box-shadow: 1px 2px 20px rgb(0 0 0 / 9%);
}

.input-group:focus-within .input-group-text i,
.form-control:focus~.input-group-text i {
  color: #2355b4;
}

table img {
  width: 17px;
  height: 17px;
}

.dashboard-graph select.form-select {
  padding-right: 0;
}

.page-item.active .page-link {
  color: #193b3c;
  background-color: unset;
  border-color: unset;
  border: 1px solid #00a14b;
}

.table-main .row.pagination-section {
  align-items: center;
  background: rgba(243, 245, 246, 0.3);
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 0px 0px 6px 6px;
  margin-top: 2rem;
}

.table-main .row.pagination-section p.font-14px {
  color: #848f9f;
}

.table-main .row.pagination-section nav.float-right {
  float: right;
}

li.page-item .page-link {
  border: unset;
  color: #193b3c;
  background-color: #fcfcfc;
}

button.btn.btn-primary {
  border-radius: 8px;
  font-weight: 500;
}

.dashboard-pills .dashboard-form .order-md-last .dashboard-form-inner .formt {
  margin: 1rem 0 !important;
}

.fa.fa-info-circle.warning-text {
  color: #fbbc04;
}

button.btn-close {
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 6%);
  padding: 5px;
}

.dashboard-pills .new-radius {
  border-radius: 0px 16px 16px 16px !important;
}

.view-all-projects-btn {
  padding: 10px;
  float: right;
}

.project-detail .project-form {
  background-color: white;
  border-radius: 10px;
}

.project-detail .project-title h1 {
  font-size: 2rem;
}

.project-detail .project-title {
  padding: 2rem 2rem 0;
}

.energy-token div.col-6:nth-child(2),
.home-nature-section div.col-6:nth-child(2) {
  padding: 0 !important;
}

.energy-token div.align-self-center p:nth-child(3),
.home-nature-section div.align-self-center p:nth-child(3) {
  overflow-y: scroll;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.energy-token div.align-self-center p:nth-child(3)::-webkit-scrollbar-thumb,
.home-nature-section div.align-self-center p:nth-child(3)::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}

.home-nature-section div.align-self-center p:nth-child(3)::-webkit-scrollbar,
.energy-token div.align-self-center p:nth-child(3)::-webkit-scrollbar {
  width: 6px;
  border-radius: 50%;
}

.promotion-banner-main .table-responsive img {
  width: 72px;
  height: 72px;
}

.project-detail .map-btn {
  margin-top: 26px;
}

.all-cms-pages .banner-image-main img {
  height: auto;
}

.slider-vertical-control.slider-controls {
  width: 100% !important;
}

.slider-vertical-control.slider-controls .slick-cloned {
  display: none !important;
}

.section-6.smart_investors {
  padding: 6%;
  background: var(--white);
}

.section-7.partners {
  padding: 6%;
  background: var(--white);
}

.logged-user.btn-warning {
  margin-bottom: 5px;
}

.lock-unlock-nfts .dropdown-menu.show {
  width: 100%;
}

.nft-select {
  width: 100%;
  height: 40px;
  border: transparent;
  border-radius: 5px;
}

.buy-token-bank-detail {
  border-radius: 10px;
  padding: 10px;
  background-color: #f3f5f6;
}

.img-about-us {
  width: 100%;
  height: 85vh;
}

@media screen and (max-width: 350px) {
  .footer-bottom-main .card.footer-cards p.font-14 {
    font-size: 12px;
  }

  #root .banner-image-main button {
    top: 50px !important;
  }

  form .otp-inputs input,
  .card .card-body .qrCode-input input {
    width: 30px !important;
    height: 30px;
  }
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

@media (max-width: 575px) {
  .signup-main .signup-leaf-section img {
    width: 50%;
  }

  .signup-section-2 h1,
  .login-section-2 h1 {
    font-size: 28px !important;
  }

  #root .banner-image-main button {
    top: 100px;
    padding: 0.875rem;
  }

  .banner-right-image .columns {
    display: block;
  }

  .otp-inputs input,
  .card-body .qrCode-input input {
    width: 35px !important;
    height: 35px;
  }

  .card.footer-cards .twitter {
    margin: 0 15px;
  }

  .other-project-select {
    width: 100%;
  }

  .banner-right-image .first-column,
  .banner-right-image .second-column,
  .banner-right-image .third-column {
    display: flex;
    justify-content: center;
  }

  .banner-right-image .columns img {
    height: 80px;
  }

  .banner-right-image .columns .first-column div:nth-child(2),
  .banner-right-image .columns .second-column div:nth-child(2),
  .banner-right-image .columns .third-column div:nth-child(2) {
    padding-left: 20px;
  }

  .img-about-us {
    height: 40vh;
  }
}

@media (max-width: 767.98px) {
  .footer-social {
    display: none;
  }

  .count-entry-card-main .card-body h2 {
    font-size: 15px !important;
  }

  .count-entry-card-main .card-body p {
    font-size: 14px !important;
  }

  .fact-and-figures .img-right {
    display: none;
  }

  .project-slider.col-md-7 {
    flex-direction: column-reverse;
    display: flex;
    margin-bottom: 40px;
  }

  .slider-vertical-control {
    margin: 10px 0;
  }

  .carousel-inner.vertical {
    padding: 10px 0 5px;
  }

  .dashboard-tiles {
    margin-top: 5rem !important;
  }

  .wallet-form-background {
    padding: 25px !important;
  }

  .wallet-form-background .wallet-form {
    width: 100%;
  }

  .wallet-form-background button.kyc-sb,
  .wallet-form-background a.ad-sc {
    font-size: 20px;
  }

  .success-alert,
  .error-alert {
    width: 90%;
  }

  .table> :not(caption)>*>* {
    overflow-wrap: break-word;
    white-space: nowrap;
  }

  .kyc-verification .w-20 {
    width: 100%;
  }

  .kyc-verification .w-100.kyc {
    margin-top: 6rem;
  }

  .side-bar-main {
    display: none;
  }

  .navbar .navbar-collapse {
    display: none;
  }

  .dashboard-tiles .mx-1,
  .dashboard-tiles .mx-1 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .header-main nav a.active,
  .header-main nav a.logo {
    margin: 0 auto;
  }

  .discover-button {
    font-weight: 600;
  }

  .order {
    order: -1;
  }

  .home-fact-and-figures {
    padding: 3rem 0.5rem !important;
  }

  .energy-token {
    padding-top: 3rem !important;
  }

  .home-investors {
    padding-bottom: 3rem !important;
  }

  .tab-pane .dasboard-numbers {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }

  .project-detail-tab.tab-pane {
    margin: 3rem 1rem !important;
  }

  .project-detail .project-title {
    width: 85%;
  }

  .project-detail .map-btn {
    width: 10%;
    margin-top: 26px;
  }

  .project-detail .mb-3 {
    display: flex;
  }

  .project-detail .yellow-warning .justify-content-end {
    justify-content: flex-start !important;
    width: 7%;
  }

  .project-detail .yellow-warning .col-sm-11 {
    width: 93%;
  }

  .image-with-transparent-text img.card-img-top {
    min-height: 350px;
  }

  .single-slider-img {
    height: 200px;
  }

  .copy-right-desktop {
    display: none;
  }

  .card-body.projects .card-body.p-lg-3 .font-30 {
    font-size: 20px !important;
  }

  .project-detail .project-title h1 {
    font-size: 20px;
  }

  .project-detail .project-title .smart-contract {
    font-size: 11px;
  }

  .header-margin.project-detail {
    margin-top: 7rem !important;
  }

  .nature-energy-aboutus {
    padding: 3rem 0.5rem !important;
  }

  .projects-section .headline {
    top: 0;
  }

  .projects-section .headline .headline1 {
    font-size: 22px;
  }

  .projects-section .headline .headline2 {
    font-size: 26px;
  }

  .nature-project-list img {
    min-height: 250px;
    max-height: 250px;
  }

  .banner-image-main button {
    top: 50px;
  }

  .banner-image-main button:nth-child(2) {
    left: 28% !important;
  }

  .promotion-banner-main .table-responsive img {
    width: 40px;
    height: 40px;
  }

  .contact-us-main .card-body .m-5 {
    margin: 3rem 1rem !important;
  }

  .smart-contract-tile {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
  }

  .w-100.dropdown #set-dd {
    text-align: left;
  }

  .w-100.dropdown #set-dd.dropdown-toggle::after {
    margin-top: 8px;
    float: right;
  }

  .w-100.dropdown .dropdown-menu {
    width: 100%;
  }

  .kyc-verification {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .global-community .p-5 {
    padding: 1rem !important;
  }

  .page-content {
    margin-left: 0rem;
  }
}

@media (min-width: 767.98px) {
  .fnfentry-card {
    /* position: absolute; */
  }

  .fact-and-figures .img-right {
    display: block;
  }

  .nature-energy-aboutus .align-self-center {
    padding-top: 12rem !important;
  }

  .project-slider {
    display: flex;
  }

  .project-slider .col-md-10 {
    padding-left: 10px;
  }

  .single-slider-img {
    height: 500px;
  }
}

@media (min-width: 767.98px) and (max-width: 991.98px) {
  .kyc-verification .w-20 {
    width: 40%;
  }

  .kyc-verification .w-100.kyc {
    margin-top: 6rem;
  }

  /* .side-bar-main .card.h-100 {
    top: 5rem;
  } */

  .dashboard-tiles.m-md-5 {
    margin: 3rem 0 !important;
  }

  .dashboard-tiles.kyc-verification {
    padding-top: 2.5rem !important;
  }

  .table> :not(caption)>*>* {
    overflow-wrap: break-word;
    white-space: nowrap;
  }

  .image-only {
    flex: 0 0 auto;
    width: 100%;
  }

  .banner-image-main button {
    top: 50px;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    font-size: 14px;
  }

  .img-about-us {
    height: 60vh;
  }
}

@media (max-width: 991px) {
  .global-community {
    flex-direction: column-reverse;
  }

  .wallet-form-background .wallet-form {
    width: 100%;
  }

  .dashboard-pills .dashboard-tabs {
    width: 160px;
  }

  .kyc-verification .kyc-heading {
    font-size: 20px;
  }

  .dashboard-pills .dashboard-form .order-md-last {
    padding: 3rem 0 !important;
  }

  .table-main .row.pagination-section p.font-14px {
    margin-top: 1rem;
  }

  .cms-pages .p-md-4 {
    padding: 3rem 1rem !important;
  }

  .banner-image-main button:nth-child(1) {
    left: 0%;
  }

  .banner-image-main button:nth-child(2) {
    left: 15%;
  }

  .all-cms-pages .banner-image-main {
    height: 100%;
  }
}

@media (min-width: 991.98px) {
  .home-nature-section {
    /* margin-top: -28%; */
    /*opacity: 0.9;*/
  }

  .signup-main,
  .login-main {
    margin-bottom: 0rem !important;
  }

  .table-form .dropdown {
    width: auto !important;
  }

  .lock-unlock-nfts .w-100.dropdown #set-dd.dropdown-toggle::after {
    margin-top: 8px;
    float: right;
  }

  .lock-unlock-nfts .w-100.dropdown #set-dd {
    text-align: left;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .signup-main .signup-section-2 {
    margin-top: 10%;
  }

  .signup-main .signup-section-2 h1,
  .login-main .login-section-2 h1 {
    font-weight: 500;
    font-size: 40px;
  }

  .signup-main .card-body.signup-body {
    padding: 0 8rem 2rem !important;
  }

  .signup-main .signup-leaf-section {
    left: 95%;
    top: -15%;
  }

  .leaf-section {
    margin-bottom: -7rem;
  }

  .banner-image-main button {
    top: 1rem;
  }

  .banner-image-main button:nth-child(1) {
    left: 0%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .banner-image-main button {
    top: 4rem;
  }

  .banner-image-main button:nth-child(1) {
    left: 0%;
  }

  .banner-image-main button:nth-child(2) {
    left: 15%;
  }

  .textover {
    margin-top: -13% !important;
  }
}

@media (min-width: 1600px) {
  .banner-image-main button {
    top: 4rem;
  }

  .banner-image-main button:nth-child(1) {
    left: 0%;
  }

  .banner-image-main button:nth-child(2) {
    left: 15%;
  }

  .textover {
    margin-top: -13% !important;
  }
}

.home-main .about-section .title {
  font-family: 'Poppins';
  font-size: 2rem;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 1.5rem;
  margin-top: 0px;
}

.home-main .about-section .desc {
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 400;
  line-height: 27px;
}

.home-main .figure-name {
  color: var(--tertiary);
  text-align: center;
  font-family: 'Poppins';
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
}

.home-main .figure-value {
  color: var(--secondary);
  font-family: 'Poppins';
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.btn:active,
.btn:focus {
  box-shadow: none;
  outline: none;
}

.btn:disabled {
  color: #FFFFFF;
  background-color: #00a14b;
  border: 2px solid #00a14b;
  opacity: unset
}

.btn.wallet-connect:disabled {
  color: #FFFFFF;
  background-color: #fad702;
  border: 2px solid #fad702;
  opacity: unset
}

.btn {
  /* min-width: 180px;
  height: 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  /* padding: 12px 25px; */
}

.btn-extra-size {
  min-width: 180px;
  height: 50px;
  padding: 12px 25px;
}

.btn-darkgreen {
  color: #FFFFFF;
  background-color: #186200;
  border: 2px solid #186200;
}

.btn-darkgreen:hover,
.btn.btn-darkgreen:active {
  background-color: transparent;
  color: #186200;
  border: 2px solid #186200;
}

.btn.nav-link {
  background-color: transparent;
  color: #186200;
  border: 2px solid #186200;
  border-radius: 30px !important;
}

.btn.nav-link.active,
.btn.nav-link:hover {
  background-color: #186200 !important;
  color: #FFFFFF !important;
  border: 2px solid #186200;
}

/* Energy NFT Page Style */
.service-nft .card {
  border-radius: 30px;
}

.service-nft .form-select {
  background-image: url(../icons/down-filled-triangular-arrow.svg);
}

.service-nft .service-hero {
  height: 340px;
  width: 100%;
  background-image: url('../images/energy-nft-hero-bg.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px 30px 0px 0px;
}

.service-nft .service-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service-nft .service-title-bar select:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #186200;
}

.service-nft .service-content {
  padding: 60px;
}

.service-nft .counter-item-content {
  position: relative;
  min-height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #FFFFFF;
  border-radius: 15px;
  border: 1px solid transparent;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 30px;
  z-index: 10;
  transition: all 0.3s ease-in-out;
}

.service-nft .counter-item-content:hover {
  border: 1px solid #186200;
  box-shadow: none;
}

.service-nft .counter-item .counter-item-content:after {
  height: 120px;
  width: 120px;
  content: "";
  position: absolute;
  bottom: -20px;
  right: 0px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.service-nft .counter-item:nth-child(1) .counter-item-content:after {
  background-image: url('../images/growth.png');
}

.service-nft .counter-item:nth-child(2) .counter-item-content:after {
  background-image: url('../images/circular-economy.png');
}

.service-nft .counter-item:nth-child(3) .counter-item-content:after {
  background-image: url('../images/dollar.png');
}

.service-nft .counter-item:nth-child(4) .counter-item-content:after {
  background-image: url('../images/tokens.png');
}

.service-nft .counter-item .counter-item-content h3 {
  font-size: 30px;
  font-weight: 500;
}

.service-nft .counter-item .counter-item-content p {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: #186300;
}

.service-nft .listing-style {
  background-color: #f3f3f3;
  border-radius: 10px;
}

.service-nft .listing-style ul {
  display: flex;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.service-nft .listing-style ul li {
  position: relative;
}

.service-nft .listing-style ul li+li {
  padding-left: 2rem;
}

.service-nft .listing-style ul li:after {
  height: 20px;
  width: 1px;
  content: "";
  position: absolute;
  top: 50%;
  right: -1rem;
  transform: translateY(-50%);
  background-color: #d6d6d6;
}

.service-nft .listing-style ul li:last-child:after {
  display: none;
}

.service-nft .listing-style ul li,
.service-nft .listing-style ul li span {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
}

.service-nft .listing-style ul li a svg #Copy {
  fill: #186200;
}

.service-nft .document-style {
  background-color: #f3f3f3;
  border-radius: 10px;
}

.service-nft .document-style ul {
  display: flex;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.service-nft .document-style ul li {
  position: relative;
}

.service-nft .document-style ul li+li {
  padding-left: 2rem;
}

/* .service-nft .document-style ul li:after {
  height: 20px;
  width: 1px;
  content: "";
  position: absolute;
  top: 50%;
  right: -1rem;
  transform: translateY(-50%);
  background-color: #d6d6d6;
} */

.service-nft .document-style ul li:last-child:after {
  display: none;
}

.service-nft .document-style ul li,
.service-nft .document-style ul li span {
  color: #333333;
  font-size: 16px;
  font-weight: 500;
}

.service-nft .document-style ul li a svg #Copy {
  fill: #186200;
}

.service-nft .service-content .form-box {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 40px;
}

.service-nft .service-content form .form-label,
.service-nft .service-content form p {
  font-size: 14px;
  font-weight: 500;
}

.service-nft .service-content form .form-control,
.service-nft .service-content form .form-select {
  height: 50px;
}

.service-nft .service-content form .form-control:focus,
.service-nft .service-content form .form-select:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #186200;
}

.service-nft .service-content form p {
  color: #186300;
}

.service-nft .service-content .info-box {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  background-color: #deb69c;
  border-radius: 15px;
  padding: 40px;
}

.service-nft .service-content .info-box .info-img svg {
  height: 30px;
  width: 30px;
}

.service-nft .service-content .info-box .info-img img {
  height: 30px;
  width: 30px;
}

.service-nft .service-content .info-box .info-content p {
  font-size: inherit;
  color: #000000;
}

.service-nft .result-box {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #deb69c;
  border-radius: 15px;
  overflow: hidden;
  z-index: 2;
  padding: 40px;
}

.service-nft .result-box:after {
  height: 251px;
  width: 255px;
  content: "";
  position: absolute;
  bottom: -20px;
  right: 0px;
  background-image: url('../images/bg-logo-img.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  opacity: 0.15;
}

.service-nft .result-box h3 {
  font-size: 30px;
  font-weight: 600;
}

.service-nft .result-box p {
  font-size: 22px;
  font-weight: 500;
  padding: 0 100px;
}

.document-btn {
  border: 1px solid black;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.document-btn i {
  margin: 5px 10px;
  color: #186200;
}

.footer {
  float: left;
  background-color: #FFFFFF;
  position: relative;
}

.footer .copyright {
  padding: 15px 30px;
}

.footer p,
.footer p a {
  font-size: 14px;
  font-weight: 400;
  color: #193B3C;
}

.footer p a:hover {
  opacity: 0.9;
}

.dropdown-menu[data-bs-popper] {
  position: absolute;
  right: 0px;
  left: initial;
}

.nav-link.dropdown-toggle {
  background-color: #186300;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  padding-right: 10px;
}

.nav-link.dropdown-toggle img {
  height: 20px;
  width: 20px;
}

.nav-link.dropdown-toggle:hover {
  background-color: #ffc107;
  color: #000000;
}

.margin-left-0 {
  margin-left: 0rem !important;
}

.margin-left-1 {
  margin-left: 1rem !important;
}

@media only screen and (max-width: 1524px) {

  .service-nft .listing-style ul li,
  .service-nft .listing-style ul li span {
    font-size: 14px;
  }

  .service-nft .listing-style ul {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .service-nft .listing-style ul li+li {
    padding-left: 0px;
  }

  .service-nft .listing-style ul li {
    position: initial;
  }

  .service-nft .listing-style ul li:after {
    right: -25px;
  }

  .side-bar-main {
    width: 13%;
  }

  .page-content {
    width: 87%;
    margin-left: 13%;
  }
}

@media only screen and (max-width: 991px) {
  .service-nft .service-content {
    padding: 30px;
  }

  .service-nft .listing-style ul li span {
    word-break: break-all;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    min-width: 150px;
  }

  .service-nft .service-content .form-box {
    box-shadow: none;
    padding: 0px;
  }

  .service-nft .service-content .info-box {
    gap: 15px;
    flex-direction: column;
    padding: 20px;
  }

  .service-nft .result-box p {
    padding: 0px;
  }

  .page-content {
    width: 100%;
    margin-left: 0%;
  }

  .dropdown-toggle span {
    font-size: 0px;
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 5px;
  }

  .nav-link.dropdown-toggle {
    padding: 10px 15px 10px 10px;
  }
}